import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <section className="section--footer" id="footer">
      <div className="container grid grid--3-cols">
        <div className="logo-col">
          <Link to="/" className="footer-logo">
            <img className="logo" alt="Lexa solution logo" src="img/logo.png" />
          </Link>
          <ul className="social-links">
            <li>
              <a
                className="footer-link"
                href="https://www.facebook.com/Lexa-solution-105604905272641"
                target={"_blank"}
              >
                <FacebookIcon className="contact-icon" />
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://www.linkedin.com/company/77030681/admin/"
                target={"_blank"}
              >
                <LinkedInIcon className="contact-icon" />
              </a>
            </li>
          </ul>
          <p className="copyright">
            Copyright © 2016 by Lexa solution, Inc. All rights reserved.
          </p>
        </div>

        <div className="quick-link">
          <p className="footer-heading">Quick Links</p>
          <ul className="footer-nav">
            <li>
              <a href="#service" className="footer-link">
                Services
              </a>
            </li>
            <li>
              <Link to="career" className="footer-link">
                Career
              </Link>
            </li>
            <li>
              <a href="#about" className="footer-link">
                About
              </a>
            </li>
            <li>
              <a href="#team" className="footer-link">
                Team
              </a>
            </li>
            <li>
              <a href="#testimonials" className="footer-link">
                Testimonials
              </a>
            </li>
          </ul>
        </div>

        <div className="address-col">
          <p className="footer-heading">Contact us</p>
          <address className="contact">
            <p className="address">
              211 ambika pinnacle, beside Lajamni Chowk, Mota Varachha, Surat,
              Gujarat 394101
            </p>
            <p>
              <a className="footer-link" href="tel:+91 9099414492">
                +91 9099414492
              </a>
              {/* <br />
              <a
                className="footer-link"
                href="mail:kumbhani.bhavesh.1@gmail.com"
              >
                kumbhani.bhavesh.1@gmail.com
              </a> */}
            </p>
          </address>
        </div>
      </div>
    </section>
  );
};

export default Footer;
