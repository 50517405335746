import React, { useEffect, useState } from "react";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

const States = () => {
  let [project, setproject] = useState(0);
  let [client, setClient] = useState(0);
  let [idea, setIdea] = useState(0);
  let [coffee, setCoffee] = useState(0);
  let [hours, setHours] = useState(0);

  useEffect(() => {
    const sectionHero = document.querySelector(".section--about");
    const obs = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        if (ent.isIntersecting === false) {
          const state_project = parseInt(45);
          setInterval(() => {
            if (project <= state_project) {
              setproject(project++);
            }
          }, 100);

          const state_client = parseInt(15);
          setInterval(() => {
            if (client <= state_client) {
              setClient(client++);
            }
          }, 200);

          const state_idea = parseInt(150);
          setInterval(() => {
            if (idea <= state_idea) {
              setIdea(idea++);
            }
          }, 30);

          const state_coffee = parseInt(450);
          setInterval(() => {
            if (coffee <= state_coffee) {
              setCoffee(coffee++);
            }
          }, 15);

          const state_hours = parseInt(1200);
          setInterval(() => {
            if (hours <= state_hours) {
              setHours(hours++);
            }
          }, 5);
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-80px",
      }
    );
    obs.observe(sectionHero);
  }, []);

  return (
    <section className="section--state" id="state">
      {/* <div cclassNamelass="bg-video">
        <video className="bg-video__content" autoplay muted loop>
          <source src="img/1.mp4" type="video/mp4" />
          <source src="img/1.webm" type="video/webm" />
          Your browser is not supported!
        </video>
      </div> */}
      <div className="state--container state--grid">
        <div className="state--part counter">
          <AppRegistrationOutlinedIcon
            className="state-icon"
            fontSize="large"
          ></AppRegistrationOutlinedIcon>
          <h2>{project}</h2>
          <p>PROJECTS</p>
        </div>
        <div className="state--part">
          <PeopleAltOutlinedIcon
            className="state-icon"
            fontSize="large"
          ></PeopleAltOutlinedIcon>
          <h2>{client}</h2>
          <p>HAPPY CLIENTS</p>
        </div>
        <div className="state--part">
          <LightbulbOutlinedIcon
            className="state-icon"
            fontSize="large"
          ></LightbulbOutlinedIcon>
          <h2>{idea}</h2>
          <p>CRAZY IDEAS</p>
        </div>
        <div className="state--part">
          <FreeBreakfastOutlinedIcon
            className="state-icon"
            fontSize="large"
          ></FreeBreakfastOutlinedIcon>
          <h2>{coffee}</h2>
          <p>COFFEE CUPS</p>
        </div>
        <div className="state--part">
          <AccessTimeOutlinedIcon
            className="state-icon"
            fontSize="large"
          ></AccessTimeOutlinedIcon>
          <h2>{hours}</h2>
          <p>HOURS</p>
        </div>
      </div>
    </section>
  );
};

export default States;
