export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          },
          borderRadius: '15px'
        },
        sizeLarge: {
          height: 57,
          width: 'auto'
        },
        sizeSmall: {
          height: 29,
          width: 'auto' // 134
        },
        sizeMedium: {
          height: 44,
          width: 'auto' // 124
        },
        sizeLargeX: {
          width: 352,
          height: 58
        },
        sizeLargeXX: {
          width: 255,
          height: 65
        },
        containedInherit: {
          color: theme.palette.grey[800],
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          color: theme.palette.grey[0],
          '&:hover': {
            backgroundColor: theme.palette.grey[1]
          },
          '&:active': {
            backgroundColor: theme.palette.grey[2]
          }
        },
        containedSecondary: {
          color: theme.palette.grey[3],
          backgroundColor: theme.palette.grey[4],
          '&:hover': {
            backgroundColor: theme.palette.grey[0],
            color: theme.palette.grey[5]
          },
          '&:active': {
            backgroundColor: theme.palette.grey[0],
            color: theme.palette.grey[6]
          }
        },
        textPrimary: {
          background: theme.palette.background.default,
          color: theme.palette.secondary.color,
          '&:hover': {
            color: theme.palette.text.secondary,
            background: theme.palette.secondary.main
          },
          '&:active': {
            backgroundColor: theme.palette.background.info,
            color: theme.palette.text.secondary
          }
        },
        textSecondary: {
          background: theme.palette.background.default,
          color: theme.palette.primary.main,
          fontWeight: 600,
          fontSize: 16
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        outlinedError: {
          border: `1px solid ${theme.palette.error.main}`
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.primary.main}`
        },
        outlinedSuccess: {
          border: `1px solid ${theme.palette.success.main}`
        },
        outlinedWarning: {
          border: `1px solid ${theme.palette.warning.main}`
        },
        outlinedSecondary: {
          border: `1px solid ${theme.palette.secondary.main}`
        },
        outlinedInfo: {
          border: `1px solid ${theme.palette.info.main}`
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}
