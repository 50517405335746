import React, { useState } from "react";

const Contact = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const contactMe = async () => {
    // const response = await fetch(
    //   "https://lexasolution-c5e49-default-rtdb.firebaseio.com/contact.json",
    //   {
    //     method: "POST",
    //     body: JSON.stringify({
    //       name: name,
    //       email: email,
    //       message: message,
    //     }),
    //     headers: {
    //       ContentType: "application/json",
    //     },
    //   }
    // );
    setName("");
    setEmail("");
    setMessage("");
  };
  const changeName = (e) => {
    setName(e.target.value);
  };
  const changeEmail = (e) => {
    setEmail(e.target.value);
  };
  const changeMessage = (e) => {
    setMessage(e.target.value);
  };

  return (
    <section className="section--contact" id="contact">
      <div className="container grid grid--2-cols">
        <div className="contact--image">
          <img src="img/contact.svg" />
        </div>
        <div className="contact--part">
          <h1>Get In Touch</h1>
          {/* <form className="contact-form" name="contact" netlify> */}
          <form name="fileForm">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Your name"
                name="name"
                value={name}
                onChange={changeName}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email Id"
                name="email"
                value={email}
                onChange={changeEmail}
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                row="7"
                placeholder="Message"
                name="message"
                value={message}
                onChange={changeMessage}
              ></textarea>
            </div>
            <button
              type="button"
              onClick={contactMe}
              className="btn btn--blue btn--animated mrg-top"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
