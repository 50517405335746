import React, { useEffect } from "react";

const Subheader = () => {
  useEffect(() => {}, []);

  return (
    <>
      {/* ---------------- Header ---------------- */}
      <section className="section--header">
        <div className="slider1">
          <div className="header--text">
            <h2 className="heading-primary">
              We are Solving problems by building
            </h2>
            <p className="heading-secondary">
              <div className="content">
                <div className="content__container">
                  <ul className="content__container__list">
                    <li className="content__container__list__item">
                      Web applications
                    </li>
                    <li className="content__container__list__item">
                      Android mobile applications
                    </li>
                    <li className="content__container__list__item">
                      iOS mobile applications
                    </li>
                    <li className="content__container__list__item">
                      SaaS platforms
                    </li>
                    <li className="content__container__list__item">
                      MVP for Startups
                    </li>
                    <li className="content__container__list__item">
                      On-Demand apps
                    </li>
                    <li className="content__container__list__item">
                      E-Commerce apps
                    </li>
                    <li className="content__container__list__item">ERP</li>
                  </ul>
                </div>
              </div>
            </p>
            <a
              className="btn btn--blue btn--animated"
              href="mailto:kumbhani.bhavesh.1@gmail.com"
            >
              Hire Us
            </a>
          </div>
          <div className="header--img">
            <img src="/img/slider/4.svg" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Subheader;
