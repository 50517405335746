import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Header from "./comman/header";
import Footer from "./comman/footer";
const Career = () => {
  const [selectedId, setSelectedId] = useState(1);

  const accordian = (e, id) => {
    setSelectedId(selectedId == id ? 0 : id);
  };

  return (
    <main>
      <Header />
      <section className="career">
        <div className="container flex--item">
          <div>
            <div
              id="zero"
              className="accordian-header"
              onClick={(e) => accordian(e, 1)}
            >
              <div className="round">
                <img src="img/hr.png" />
              </div>
              <h2>HR Executive</h2>
              <div>
                {1 === selectedId ? (
                  <KeyboardArrowUpIcon fontSize="large"></KeyboardArrowUpIcon>
                ) : (
                  <KeyboardArrowDownIcon fontSize="large"></KeyboardArrowDownIcon>
                )}
              </div>
            </div>
            <div className={1 === selectedId ? "shome--me" : "hide--me"}>
              <h2>Job Description</h2>
              <p>
                We are looking for an HR Recruiter to manage our full cycle
                recruitment, from identifying potential hires to interviewing
                and evaluating candidates.
              </p>
              <h2>Roles & Responsibilities</h2>
              <ul>
                <li>
                  Partnering with hiring managers to determine staffing needs
                </li>
                <li>Screening resumes</li>
                <li>
                  Performing in-person and phone interviews with candidates
                </li>
                <li>Administering appropriate company assessments</li>
                <li>Performing reference and background checks</li>
                <li>Making recommendations to company hiring managers</li>
                <li>Coordinating interviews with the hiring managers</li>
                <li>Following up on the interview process status</li>
              </ul>
              <h2>Skills</h2>
              <ul>
                <li>Excellent communication skills.</li>
                <li>Fluent in English</li>
                <li>Analytical mind and problem-solving aptitude</li>
              </ul>
              <div className="years">
                <h2> Experience: </h2>
                <p>6 months to 2 year</p>
              </div>
              <a
                href="https://wa.me/919099414492"
                className="btn--full btn "
                target={"_blank"}
              >
                Apply Now
              </a>
            </div>
          </div>

          <div>
            <div
              id="first"
              className="accordian-header"
              onClick={(e) => accordian(e, 2)}
            >
              <div className="round">
                <img src="img/react.png" />
              </div>
              <h2>React JS Developer</h2>
              <div>
                {2 === selectedId ? (
                  <KeyboardArrowUpIcon fontSize="large"></KeyboardArrowUpIcon>
                ) : (
                  <KeyboardArrowDownIcon fontSize="large"></KeyboardArrowDownIcon>
                )}
              </div>
            </div>
            <div className={2 === selectedId ? "shome--me" : "hide--me"}>
              <h2>Job Description</h2>
              <p>
                We have a React JS Developer job vacancy at Surat. We are
                looking for an experienced JavaScript developer who is
                proficient with React.js. Working mainly on the front-end part
                of our application you will build user-facing components and
                implement them with different workflows and tool chests like
                Redux and Flux.
              </p>
              <h2>Roles & Responsibilities</h2>
              <ul>
                <li>
                  Strong proficiency in JavaScript, including DOM manipulation
                  and the JavaScript object model
                </li>
                <li>
                  Thorough understanding of React.js and its core principles
                </li>
                <li>
                  Familiarity with code versioning tools (such as Git, SVN, and
                  Mercurial)
                </li>
                <li>
                  Working with Project Management to scope new functionality
                  requests and supporting Sales to scope new projects
                </li>
                <li>
                  Intense passion and strong desire to be successful in the
                  field of web development or programming
                </li>
                <li>
                  Use your knowledge on React.js and its lifecycle to maximize
                  components performance across different devices an browsers
                </li>
                <li>A great team player</li>
              </ul>
              <h2>Skills</h2>
              <ul>
                <li>
                  Experience with popular React.js workflows (such as Flux or
                  Redux)
                </li>
                <li>
                  Deep understanding of React.js and its fundamentals, ideally
                  including modern features
                </li>
                <li>Knowledge of Restful APIs</li>
              </ul>
              <div className="years">
                <h2> Experience: </h2>
                <p>Fresher to 3 years</p>
              </div>
              <a
                href="https://wa.me/919099414492"
                className="btn--full btn "
                target={"_blank"}
              >
                Apply Now
              </a>
            </div>
          </div>

          <div>
            <div
              id="second"
              className="accordian-header"
              onClick={(e) => accordian(e, 3)}
            >
              <div className="round">
                <img src="img/node.png" />
              </div>
              <h2>Node JS Developer</h2>
              <div>
                {3 === selectedId ? (
                  <KeyboardArrowUpIcon fontSize="large"></KeyboardArrowUpIcon>
                ) : (
                  <KeyboardArrowDownIcon fontSize="large"></KeyboardArrowDownIcon>
                )}
              </div>
            </div>
            <div className={3 === selectedId ? "shome--me" : "hide--me"}>
              <h2>Job Description</h2>
              <p>
                We have a Node JS Developer job vacancy at Surat. We are looking
                for an experienced Node JS Developer to join our IT team who
                will be responsible for the server side of our web applications.
              </p>
              <h2>Roles & Responsibilities</h2>
              <ul>
                <li>
                  Integration of user-facing elements developed by front-end
                  developers with server-side logic.
                </li>
                <li>Writing reusable, testable, and efficient code.</li>
                <li>
                  Design and implementation of low-latency, high-availability,
                  and performant applications.
                </li>
                <li>Follow emerging technologies</li>
                <li>Test and develop software for client applications</li>
                <li>
                  English communication for understanding and daily update.
                </li>
              </ul>
              <h2>Skills</h2>
              <ul>
                <li>Minimum 2-3 years’ experience in back-end development</li>
                <li>Hands on experience with programming languages</li>
                <li>
                  Good communication, analytical and presentation skills,
                  problem solving skills
                </li>
              </ul>
              <div className="years">
                <h2> Experience: </h2>
                <p>Fresher to 4 years</p>
              </div>
              <a
                href="https://wa.me/919099414492"
                className="btn--full btn"
                target={"_blank"}
              >
                Apply Now
              </a>
            </div>
          </div>

          <div>
            <div
              id="second"
              className="accordian-header"
              onClick={(e) => accordian(e, 4)}
            >
              <div className="round">
                <img src="img/code.png" />
              </div>
              <h2>Front End Developer</h2>
              <div>
                {4 === selectedId ? (
                  <KeyboardArrowUpIcon fontSize="large"></KeyboardArrowUpIcon>
                ) : (
                  <KeyboardArrowDownIcon fontSize="large"></KeyboardArrowDownIcon>
                )}
              </div>
            </div>
            <div className={4 === selectedId ? "shome--me" : "hide--me"}>
              <h2>Job Description</h2>
              <p>
                We have a Front End Developer job vacancy at Surat. We are
                looking for an experienced Front-end developer to join our IT
                team. You will be responsible for the server side of our web
                applications.
              </p>
              <h2>Roles & Responsibilities</h2>
              <ul>
                <li>
                  Building out custom themes and modifying pre-existing themes
                  depending on the scope of the project
                </li>
                <li>
                  Working with Project Management to scope new functionality
                  requests and supporting Sales to scope new projects
                </li>
                <li>
                  Intense passion and strong desire to be successful in the
                  field of web development or programming
                </li>
                <li>
                  Bringing design to life using HTML/SASS/JavaScript (jQuery).
                </li>
                <li>
                  Working closely with the project and account managers to
                  deliver projects that are the best in the industry.
                </li>
                <li>
                  Collaborating and sharing ideas with the rest of the
                  production team.
                </li>
                <li>
                  Actively participate in the build-out of various web,
                  ecommerce applications.
                </li>
              </ul>
              <h2>Skills</h2>
              <ul>
                <li>
                  1+ years of experience in front-end technologies including,
                  but not limited to, JavaScript, AJAX, HTML, CSS, SASS, XML
                </li>
                <li>Hands on experience with programming languages</li>
                <li>
                  Good communication, analytical and presentation skills,
                  problem solving skills
                </li>
                <li>Strong understanding of Front-end development</li>
              </ul>
              <div className="years">
                <h2> Experience: </h2>
                <p>Fresher to 3 years</p>
              </div>
              <a
                href="https://wa.me/919099414492"
                className="btn--full btn"
                target={"_blank"}
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Career;
