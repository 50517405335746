// routes
import Router from "routes";
// theme
import ThemeConfig from "theme";
import GlobalStyles from "theme/globalStyles";
// hooks
// import useAuth from "hooks/useAuth";
// components
import RtlLayout from "components/RtlLayout";
import ThemePrimaryColor from "components/ThemePrimaryColor";
import ThemeLocalization from "components/ThemeLocalization";
import "App.scss";
// ----------------------------------------------------------------------

export default function App() {
  // const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <GlobalStyles />
            {/* <a
              href="https://wa.me/919099414492"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/img/WhatsApp.png" className="whatsapp-icon" />
            </a> */}
            <Router />
            {/* {isInitialized ? <Router /> : <LoadingScreen />} */}
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
