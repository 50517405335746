import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  useEffect(() => {
    if (window && document) {
      const btnNavEl = document.querySelector(".btn-mobile-nav");
      const headerEl = document.querySelector(".header");

      function showHeader() {
        console.log("showHeader");
      }
      btnNavEl.addEventListener("click", function () {
        headerEl.classList.toggle("nav-open");
      });

      // Smooth scrolling animation
      const allLinks = document.querySelectorAll("a:link");

      allLinks.forEach(function (link) {
        link.addEventListener("click", function (e) {
          // e.preventDefault();
          e.stopPropagation();
          // e.nativeEvent.stopImmediatePropagation();
          const href = link.getAttribute("href");
          if (href === "#") {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
          if (href && href.includes("#")) {
            const secEl = document.querySelector(href);
            if (secEl) {
              secEl.scrollIntoView({ behavior: "smooth" });
            }
          }
          if (link.classList.contains("main-nav-link")) {
            headerEl.classList.toggle("nav-open");
          }
        });
      });

      // Header sticky

      const sectionHero = document.querySelector(".section--header");
      const obs = new IntersectionObserver(
        function (entries) {
          const ent = entries[0];
          if (ent.isIntersecting === false) {
            document.body.classList.add("sticky");
          }
          if (ent.isIntersecting === true) {
            document.body.classList.remove("sticky");
          }
        },
        {
          root: null,
          threshold: 0,
          rootMargin: "-80px",
        }
      );
      if (sectionHero) {
        obs.observe(sectionHero);
      }

      // window.addEventListener("wheel", function (event) {
      //   if (event.deltaY < 0) {
      //     console.log("scrolling up", event.wheelDelta);
      //     document.body.classList.add("sticky");
      //   } else if (event.deltaY > 0) {
      //     console.log("scrolling down");
      //     document.body.classList.remove("sticky");
      //   }
      // });
    }
  }, []);
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(`/${path}`);
  };

  return (
    <>
      <header className="header">
        <Link to="/">
          <img className="logo" alt="Lexa solution logo" src="img/logo.png" />
        </Link>
        <nav className="main-nav">
          <ul className="main-nav-list">
            <li>
              <a className="main-nav-link" href="#service">
                Services
              </a>
            </li>
            <li>
              <a
                onClick={() => handleClick("career")}
                className="main-nav-link"
              >
                Career
              </a>
            </li>
            <li>
              <a className="main-nav-link" href="#about">
                About
              </a>
            </li>
            {/* <li>
              <a className="main-nav-link " href="#team">
                Team
              </a>
            </li> */}
            <li>
              <a className="main-nav-link " href="#testimonials">
                Testimonials
              </a>
            </li>
            <li>
              <a className="main-nav-link " href="#contact">
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
        <button className="btn-mobile-nav">
          <ion-icon name="menu-outline" className="icon-mobile-nav"></ion-icon>
          <ion-icon name="close-outline" className="icon-mobile-nav"></ion-icon>
        </button>
      </header>
    </>
  );
};

export default Header;
