import React from "react";

import Header from "./comman/header";
import Footer from "./comman/footer";
import Subheader from "./comman/sub_header";
import Service from "./comman/service";
import Contact from "./comman/contact";
import States from "./comman/states";
import Team from "./comman/team";
import Testimonials from "./comman/testimonials";
import How_it_works from "./comman/how_it_works";

const Home = () => {
  const client_slider_settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <main>
      <Header />
      <Subheader />
      {/* ---------------- Service ---------------- */}
      <Service />
      {/* ---------------- About ---------------- */}
      <section className="section--about" id="about">
        <div className="container grid grid--2-cols">
          <div className="about--image">
            <img src="img/aboutus.svg" />
          </div>
          <div className="about-text">
            <h1>About US</h1>
            <h3 className="heading-tertary">Know Interesting Things</h3>
            <p className="description">
              Everything we do is about you. From the developers who build
              custom web applications to the designers who know exactly how you
              want your brand to be presented and to the digital experts who can
              help you grow your business exponentially – we prioritize what you
              need to make your company a big success. We strive to keep you at
              your best.
            </p>
          </div>
        </div>
      </section>
      {/* ---------------- State ---------------- */}
      <States />
      {/* ---------------- Team members ---------------- */}
      {/* <Team /> */}
      {/* ---------------- Testimonials ---------------- */}
      {/* <Testimonials /> */}
      {/* <How_it_works /> */}
      {/* ---------------- Get in Touch ---------------- */}
      <Contact />
      {/* ---------------- Footer ---------------- */}
      <Footer />
    </main>
  );
};

export default Home;
