import React from "react";

const Service = () => {
  return (
    <section id="service">
      <div className="c-section">
        <div className="container">
          <div className="bg-service">
            <h2 className="c-section__title">
              <span>Our Services</span>
            </h2>
          </div>
          <div>
            <ul className="c-services">
              <li className="c-services__item">
                <h3>Responsive Web Design</h3>
                <p>
                  We leverage the concept of mobile-first design. Through our
                  work, we focus on designing an experience that works across
                  different screen sizes.
                </p>
              </li>
              <li className="c-services__item">
                <h3>UX design</h3>
                <p>
                  We create user-centric experiences that are intuitive and easy
                  to use.
                </p>
              </li>
              <li className="c-services__item">
                <h3>Front End Development</h3>
                <p>
                  We are Front End masters with a deep focus on HTML, CSS. The
                  result of our work is a responsive, accessible, and performant
                  websites. Either you have the design ready and want us to code
                  it, or you want us to do both design and code, we&rsquo;re
                  happy to do so.
                </p>
              </li>
              <li className="c-services__item">
                <h3>Backend</h3>
                <p>
                  We are backend masters with a deep focus on Node.js, Express,
                  and MongoDB. The result of our work is a robust, scalable, and
                  performant backend. Either you have the design ready and want
                  us to code it, or you want us to do both design and code,
                  we&rsquo;re happy to do so.
                </p>
              </li>
              <li className="c-services__item">
                <h3>Mobile Apps Design</h3>
                <p>
                  To reach more customers and the goals of your business, a
                  mobile application is necessary these days. We will work on
                  the app design from scratch to final tested prototype.
                </p>
              </li>
              <li className="c-services__item">
                <h3>UI design</h3>
                <p>
                  We are UI masters with a deep focus on React. The result of
                  our work is a responsive, accessible, and performant websites.
                  Either you have the design ready and want us to code it, or
                  you want us to do both design and code, we&rsquo;re happy to
                  do so.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
