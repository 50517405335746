import { Navigate, useRoutes } from "react-router-dom";
import Home from "components/home";
import Career from "components/career";
import Login from "components/login/login";
export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: "",
      element: <Home />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "career",
      element: <Career />,
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}
